.header-top {
  background-color: #07294d;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
}

.sivasoft-logo {
  width: 50px;
  height: 50px;
  vertical-align: bottom;
  border: 2px solid #ffc600;
  border-radius: 25px;
}

.sivasoft-text {
  font-size: 40px;
  color: #07294d;
  margin-bottom: -6px;
  font-weight: 500;
}

a {
  color: #007bff;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

i,
span,
a {
  display: inline-block;
}

img {
  max-width: 100%;
}

.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #ffc600;
  padding: 0 35px;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 50px;
  border-radius: 5px;
  color: #07294d;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 5;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  background-color: #ffc600;
}

.main-btn:hover {
  color: #ffffff;
  border-color: #07294d;
  background-color: #07294d;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: #505050;
  margin: 0px !important;
}

.cont span {
  font-size: 18px;
  color: #07294d;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.bi {
  color: #ffc600;
  margin-left: 10px;
  margin-top: 3px;
}

.footer-top {
  background-color: #07294d;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-40 {
  padding-top: 40px;
}

.footer-about {
  padding-right: 50px;
}

.mt-40 {
  margin-top: 40px;
}

.footer-about p {
  color: #fff;
  padding-top: 15px;
}

.pb-25 {
  padding-bottom: 25px;
}

.footer-title h6 {
  font-size: 18px;
  color: #fff;
}

.footer-address ul li {
  position: relative;
  margin-bottom: 10px;
}

.footer-address ul li .icon i {
  font-size: 18px;
  color: #ffc600;
  position: absolute;
  left: 0;
  top: 0px;
}

.bi {
  color: #ffc600;
  margin-left: 15px;
}

.footer-copyright {
  background-color: #021d3a;
}

.pb-25 {
  padding-bottom: 25px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.footer-copyright .copyright p {
  color: #fff;
}

.footer-address ul li .cont p {
  color: #fff;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.footer-address ul li .cont {
  padding-left: 35px;
}

.sivasoft-footer-text {
  font-size: 40px;
  color: #ffffff;
  margin-bottom: -6px;
  font-weight: 500;
  margin-left: 5px;
}

.width40 {
  width: 40%;
}

.scroll-top-btn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.left {
  list-style-image: url(https://sivasoft.in/images/double-arrow-bullet.png);
}